/** lat/lon points based on image from https://simplemaps.com/resources/svg-de */
const imageDimensionMap = {
  points: {
    north: 55.059028,
    west: 5.86956,
    south: 47.273086,
    east: 15.029972,
  },
  heightLat: 7.785942,
  widthLon: 9.160412,
}

export default function calculatePinPointerPosition(pinData) {
  const { points, heightLat, widthLon } = imageDimensionMap
  const { lat, lon } = pinData?.location
  const x = (100 / widthLon) * (lon - points.west)
  const y = (100 / heightLat) * (points.north - lat)

  // rough adjustment for lat, lon projection to 2d map
  const northFactor = 2
  const westFactor = 2

  return {
    x: `${x - westFactor}%`,
    y: `${y - northFactor}%`,
    rawX: x,
    rawY: y,
  }
}
