import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import Container from '@objects/container'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Grid, Typography } from '@material-ui/core'
import Icon from '@objects/icon'
import clsx from 'clsx'
import ContentfulComponentArray from '@tools/ContentfulComponentArray'

import MapDateSwitch from './mapDateSwitch'
import InfoPanelContent from './infoPanelContent'
import calculatePinPointerPosition from './calculatePinPointerPosition'

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
  },
  inner: {
    position: 'relative',
  },
  copy: {
    ...theme.typography.teaser,
    marginBottom: theme.spacing(7.5),
    color: theme.palette.grey.dark,
    textAlign: 'center',
  },
  illustration: {
    position: 'absolute',
    width: '120px',
    bottom: theme.spacing(-27),
    transform: 'rotate(180deg)',
    left: theme.spacing(-8),
    [theme.breakpoints.up('md')]: {
      width: '266px',
      left: theme.spacing(-15),
      bottom: theme.spacing(-36),
    },
    [theme.breakpoints.up('lg')]: {
      bottom: theme.spacing(-40),
    },
    [theme.breakpoints.up('xl')]: {
      left: theme.spacing(-52),
    },
    zIndex: 1,
  },
  moduleContentWrapper: {
    zIndex: 10,
    position: 'relative',
  },
  pin: {
    cursor: 'pointer',
    '& svg path.pin_color': {
      transition: 'fill 0.15s ease-in-out',
    },
    '&$selectedPin': {
      '& svg path.pin_color': {
        fill: theme.palette.secondary.main,
        transition: 'fill 0.15s ease-in-out',
      },
    },
    '&$pinActive': {
      '& svg path.pin_color': {
        fill: theme.palette.secondary.main,
        transition: 'fill 0.15s ease-in-out',
      },
    },
    transition: 'transform 0.15s ease-in-out',
    '&:hover': {
      transform: 'scale(1.1726)',
      '& svg path.pin_color': {
        fill: '#aa1932',
      },
    },
  },
  selectedPin: {},
  pinActive: {},
  mapWrapper: {
    position: 'relative',
    width: '100%',
    height: 'auto',
    overflow: 'hidden',
  },
  map: {
    display: 'block',
    maxWidth: '100%',
  },
  pinInfoContainerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },
  pinInfoWrapper: {
    width: '100%',
    background: theme.palette.background.default,
    padding: theme.spacing(8),
    marginRight: theme.spacing(15),
    maxWidth: '400px',
    zIndex: 1,
  },
  infoLabel: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      marginBottom: 0,
    },
    ...theme.typography.h5,
    color: theme.palette.secondary.main,
    marginTop: theme.spacing(5),
  },
  mobileInfoPanelContainer: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    maxHeight: '50vh',
    background: theme.palette.background.default,
    zIndex: 1020,
    display: 'none',
  },
  showMobilePanel: {
    display: 'block',
  },
  mobileInfoPanel: {
    padding: theme.spacing(4),
    overflow: 'auto',
    maxHeight: '100%',
  },
  markerTriangle: {
    width: 0,
    height: 0,
    borderWidth: '0 14px 14px 14px',
    borderColor: 'transparent transparent #FFFFFF transparent',
    borderStyle: 'solid',
    position: 'relative',
    top: '-14px',
    transition: 'left 0.15s ease-in-out',
  },
  close: {
    position: 'absolute',
    top: '18px',
    right: '16px',
  },
  summaryText: {
    padding: 0,
  },
}))

const Pin = (props) => {
  const [selected, setSelected] = useState(false)
  const { pinData, setCurrentPin, ...other } = props

  const classes = useStyles()

  const calcPos = calculatePinPointerPosition(pinData)

  return (
    <div
      style={{
        position: 'absolute',
        width: '24px',
        height: '24px',
        top: calcPos.y,
        left: calcPos.x,
        zIndex: 1,
      }}
      {...other}
    >
      <Icon
        name={'LocationPin'}
        className={clsx([
          classes.pin,
          {
            [classes.selectedPin]: selected,
          },
        ])}
        style={{
          position: 'absolute',
          width: '24px',
          height: '24px',
          zIndex: 1,
        }}
        onMouseEnter={() => setSelected(true)}
        onMouseLeave={() => setSelected(false)}
      />
    </div>
  )
}

function renderInfoPanelContent({ currentPin, classes }) {
  if (!currentPin) return null
  return (
    <InfoPanelContent currentPin={currentPin} classes={classes}>
      {!!currentPin.summary?.json?.content && (
        <ContentfulComponentArray
          key={currentPin.id}
          componentarray={currentPin.summary.json.content}
          className={classes.summaryText}
        />
      )}
    </InfoPanelContent>
  )
}

function DesktopInfoPanel({ currentPin, classes, children }) {
  return (
    <Grid item xs={12} md={6} className={clsx(classes.pinInfoContainerWrapper)}>
      <Grid container direction={'column'} justify={'center'}>
        <Grid item xs={12}>
          <div className={classes.pinInfoWrapper}>
            {currentPin ? (
              children
            ) : (
              <FormattedMessage id="dialogue.event.info" />
            )}
          </div>
        </Grid>
      </Grid>
    </Grid>
  )
}

function MobileInfoPanel(props) {
  const {
    currentPin,
    setCurrentPin,
    classes,
    panelOpen,
    setPanelOpen,
    children,
  } = props

  if (currentPin) {
    const calcPos = calculatePinPointerPosition(currentPin)

    return (
      <div
        className={clsx([
          classes.mobileInfoPanelContainer,
          {
            [classes.showMobilePanel]: currentPin && panelOpen,
          },
        ])}
      >
        <div className={classes.markerTriangle} style={{ left: calcPos?.x }} />
        <Icon
          name={'Close'}
          className={classes.close}
          onClick={() => {
            setPanelOpen(false)
            setCurrentPin(null)
          }}
        />
        <div className={classes.mobileInfoPanel}>{children}</div>
      </div>
    )
  }
  return null
}

export default function EventMap({ headline, allevents }) {
  const classes = useStyles()
  const theme = useTheme()

  // future|past
  const [eventsDirection, setEventsDirection] = useState('past')
  const [currentPin, setCurrentPin] = useState(null)
  const [panelOpen, setPanelOpen] = useState(null)
  const isMedium = useMediaQuery(theme.breakpoints.up('md'))

  const events = allevents.map((event) => {
    return {
      ...event,
    }
  })

  useEffect(() => {
    setCurrentPin(null)
  }, [isMedium])

  useEffect(() => {
    setCurrentPin(null)
  }, [eventsDirection])

  return (
    <Container
      className={classes.root}
      background={theme.palette.background.grey}
      padded
      data-testid={'newsletter-teaser'}
      id={'newsletter-teaser'}
      ariaLabel="arialabel.eventmap"
    >
      <div className={classes.inner}>
        <img
          className={classes.illustration}
          src={'/img/illustration/illustration_colored_diagonal.svg'}
          alt=""
        />
        <Grid
          container
          justify="center"
          className={classes.moduleContentWrapper}
        >
          <Grid item xs={12}>
            <Typography variant="h2" component="h2" className={classes.title}>
              {headline}
            </Typography>
          </Grid>
          {isMedium ? (
            <DesktopInfoPanel currentPin={currentPin} classes={classes}>
              {renderInfoPanelContent({ currentPin, classes })}
            </DesktopInfoPanel>
          ) : (
            <MobileInfoPanel
              currentPin={currentPin}
              setCurrentPin={setCurrentPin}
              classes={classes}
              setPanelOpen={setPanelOpen}
              panelOpen={panelOpen}
            >
              {renderInfoPanelContent({ currentPin, classes })}
            </MobileInfoPanel>
          )}

          <Grid item xs={12} md={6}>
            <MapDateSwitch setEventsDirection={setEventsDirection} />
            <div className={classes.mapWrapper}>
              <img className={classes.map} src={'/img/de.svg'} alt="map" />
              {events
                .filter((item) => {
                  let eventDateMs = new Date(item.eventDate).valueOf()
                  switch (eventsDirection) {
                    case 'future':
                      return eventDateMs > Date.now()
                    case 'past':
                    default:
                      return eventDateMs <= Date.now()
                  }
                })
                .map((item, index) => {
                  return (
                    <Pin
                      key={index}
                      pinData={item}
                      className={clsx([
                        classes.pin,
                        {
                          [classes.pinActive]:
                            currentPin && currentPin.id === item.id,
                        },
                      ])}
                      onClick={() => {
                        setCurrentPin(item)
                        !isMedium ? setPanelOpen(true) : setPanelOpen(false)
                      }}
                      onMouseEnter={() => {
                        setCurrentPin(item)
                        !isMedium ? setPanelOpen(true) : setPanelOpen(false)
                      }}
                    />
                  )
                })}
            </div>
          </Grid>
        </Grid>
      </div>
    </Container>
  )
}

EventMap.propTypes = {
  headline: PropTypes.string,
  copy: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      link: PropTypes.string,
      href: PropTypes.string,
      color: PropTypes.string,
    })
  ),
}
