import React from 'react'
import { FormattedMessage } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import Switch from '@objects/formFields/switch'

const useMapSwitchStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  label: {
    flexBasis: '27%',
    textAlign: 'center',
  },
  switch: {
    flexShrink: 1,
  },
}))

export default function MapDateSwitch(props) {
  const { setEventsDirection } = props
  const classes = useMapSwitchStyles()
  return (
    <div className={classes.wrapper}>
      <div className={classes.label}>
        <Typography>
          <FormattedMessage id="dialogue.map.switch.past" />
        </Typography>
      </div>
      <div className={classes.switch}>
        <Switch
          onChange={(evt) => {
            setEventsDirection(evt.target.checked ? 'future' : 'past')
          }}
          defaultChecked={false}
        />
      </div>
      <div className={classes.label}>
        <Typography>
          <FormattedMessage id="dialogue.map.switch.future" />
        </Typography>
      </div>
    </div>
  )
}
