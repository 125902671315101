import React from 'react'
import PropTypes from 'prop-types'
import { Switch as MuiSwitch } from '@material-ui/core'

function Switch(props) {
  return <MuiSwitch {...props} />
}

//propTypes
Switch.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
}

export default Switch
