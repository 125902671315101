import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Typography } from '@material-ui/core'

import useDateFns from '@hooks/useDateFns'

export default function InfoPanelContent({ currentPin, classes, children }) {
  const { formatDate } = useDateFns()

  let formattedDate = formatDate(currentPin.eventDate, 'dd. MMM yyyy')
  let eventDateMs = new Date(currentPin.eventDate).valueOf()
  let isPastEvent = eventDateMs <= Date.now()

  return (
    <div>
      <Typography variant="h3" component="h3" className={classes.title}>
        {currentPin.title}
      </Typography>
      {currentPin?.format ? (
        <div>
          {/* format */}
          <h5 className={classes.infoLabel}>
            <FormattedMessage id="dialogue.event.format" />:{' '}
          </h5>
          {currentPin.format}
        </div>
      ) : (
        ''
      )}
      {!isPastEvent && currentPin.attending ? (
        <div>
          {/* attending */}
          <h5 className={classes.infoLabel}>
            <FormattedMessage id="dialogue.event.attending" />:{' '}
          </h5>
          {currentPin.attending}
        </div>
      ) : (
        ''
      )}

      {formattedDate ? (
        <div>
          {/* eventDate */}
          <h5 className={classes.infoLabel}>
            <FormattedMessage id="dialogue.event.date" />:{' '}
          </h5>
          {formattedDate}
        </div>
      ) : (
        ''
      )}
      {!!isPastEvent && currentPin?.summary ? (
        <div>
          {/* eventSummary */}
          <h5 className={classes.infoLabel}>
            <FormattedMessage id="dialogue.event.summary" />:{' '}
          </h5>
          {children}
        </div>
      ) : (
        ''
      )}
    </div>
  )
}
